export const initFetchCheckoutAssetsAndCaching = () => {
    const url = document.querySelector('[data-checkout-assets-url]');
    if (!url) return;

    setTimeout(async () => {
        try {
            const response = await fetch(url.dataset.checkoutAssetsUrl, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (!data) {
                throw new Error('Invalid response format');
            }

            data.js.forEach((jsFile) => {
                const scriptTag = document.createElement('link');
                scriptTag.href = jsFile;
                scriptTag.rel = 'preload';
                scriptTag.as = 'script';
                document.head.appendChild(scriptTag);
            });

            data.css.forEach((cssFile) => {
                const linkTag = document.createElement('link');
                linkTag.href = cssFile;
                linkTag.rel = 'preload';
                linkTag.as = 'style';
                document.head.appendChild(linkTag);
            });
        } catch (error) {
            console.error('Error fetching and caching assets:', error.message);
        }
    }, 3000);
};
