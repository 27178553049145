import { initMobileMenu } from './components/initMobileMenu.js';
import { initChunkPageBeforeCheckout } from './components/initChunkPageBeforeCheckout';
import { initSetEmailLocalstorage } from './components/forms/initSetEmailLocalstorage';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initScrollLocker } from '../../../../Common/Resources/src_front/js/components/initScrollLocker';
import { initStopAnimateOnResize } from '../../../../Common/Resources/src_front/js/components/initStopAnimateOnResize';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';

document.addEventListener('DOMContentLoaded', () => {
    initPageLoader();
    initChunkPageBeforeCheckout();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initHashEmail();
    initSetEmailLocalstorage();
    initScrollLocker();
    initMobileMenu();
    initRippleAnimation();
    initStopAnimateOnResize();
});

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        // Hide PageLoader
        if (window.pageLoader) {
            window.pageLoader.hide();
        }
    }
});
